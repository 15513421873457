import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useState } from "react";
import SearchBar from "../../components/SearchBar";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";

const Accepted = () => {
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];

  return (
    <div>
      <Box className="cards_header">
        <SearchBar
        // searchTerm={searchTerm}
        // setDebouncedSearchTerm={setDebouncedSearchTerm}
        // value={searchTerm}
        // onCross={() => setSearchTerm("")}
        // onChange={(val: any) => {
        //   if (isValidInput(val.target.value)) {
        //     setSearchTerm(val.target.value);
        //   }
        // }
        // }
        />
        {/* <Box className="cards_header_right">
        <Button className="btn btn_primary">
          <FileDownloadIcon /> Export CSV
        </Button>
        <Button
          className="btn btn_primary"
          onClick={() => navigate("/manage-advertisers/add")}
        >
          Add Advertiser
        </Button>
      </Box> */}
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Created Date</TableCell>
              <TableCell align="center">Advertiser Details</TableCell>
              {/* <TableCell align="center">Ad Image</TableCell> */}
              <TableCell align="center">Ad Title</TableCell>
              <TableCell align="center">Web Link</TableCell>
              <TableCell align="center">Total Cost</TableCell>
              <TableCell align="center"> Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {/* {rows.map((row, i) => ( */}
            <TableRow>
              <TableCell align="center">{1}</TableCell>
              <TableCell>12/12/2023</TableCell>
              <TableCell>
                <p>Abdul Hussain</p>
                <p>abdul@yopmail.com</p>
              </TableCell>
              <TableCell>See the Northern Lights</TableCell>
              <TableCell>www.northernlights.com</TableCell>
              <TableCell>$10</TableCell>

              <TableCell>
                <Switch {...label} defaultChecked size="small" />
              </TableCell>
              <TableCell>
                <Box className="table_actions">
                  <IconButton
                    onClick={() =>
                      navigate("/manage-advertisements/details", {
                        state: { userStatus: "ACCEPTED" },
                      })
                    }
                  >
                    <VisibilityIcon />
                  </IconButton>
                  {/* <IconButton
                    onClick={() => navigate("/manage-advertisements/edit")}
                  >
                    <ModeEditIcon />
                  </IconButton> */}
                  <IconButton>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </TableCell>
            </TableRow>
            {/* ))} */}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default Accepted;
