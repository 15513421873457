import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import VisibilityIcon from "@mui/icons-material/Visibility";

import MainContainer from "../../../layout/MainContainer";
import { DocumentViewer } from "../../../components";

const ServiceProviderDetails = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [modalType, setModalType] = useState<number>(1);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Advertiser Profile</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-advertisers");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid>
              <Grid item xs={10} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Username</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid>
                  {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Las name</Typography>
                      <Typography component="p">Abdul Hussain</Typography>
                    </Box>
                  </Grid> */}
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">
                        abdulhussian67@gmail.com
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">+971056734321</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Approval Status</Typography>
                      <Typography component="p">Approved</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Name of the Document
                      </Typography>
                      <Typography component="p">Passport</Typography>
                    </Box>
                  </Grid>

                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        Document Expiry Date
                      </Typography>
                      <Typography component="p">31/03/2025</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Box>
                      <Typography component="h5"> Address</Typography>
                      <Typography component="p">Mohali</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        No. of adds Activated
                      </Typography>
                      <Typography component="p">10</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        No. of adds De-activated
                      </Typography>
                      <Typography component="p">2</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4} md={4} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">
                        No. of adds Completed
                      </Typography>
                      <Typography component="p">7</Typography>
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box>
                      <Typography component="h5">Documents</Typography>
                      <Box className="docs_div">
                        <figure>
                          <VisibilityIcon onClick={() => setOpen(true)} />

                          <img src="/static/images/document.png" alt="" />
                        </figure>
                        <figure>
                          <VisibilityIcon onClick={() => setOpen(true)} />
                          <img src="/static/images/document.png" alt="" />
                        </figure>
                      </Box>
                    </Box>
                  </Grid>
                  {state.userStatus === "PENDING" ? (
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography
                          sx={{
                            padding: "20px 0 0",
                            fontSize: "20px",
                            color: "#1d1d1d",
                            fontWeight: "700",
                          }}
                          component="h2"
                        >
                          Approval
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Accept
                        </Button>
                        <Button
                          size="large"
                          type="submit"
                          className="btn btn_primary"
                        >
                          Reject
                        </Button>
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        {state.userStatus === "ACCEPTED" ? (
          <Card sx={{ mt: 4 }} className="cards">
            <Box className="custom_tabs">
              <h2 className="mn_hdng">Ads History</h2>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Image</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell>Url</TableCell>
                      <TableCell>Created on</TableCell>
                      <TableCell>Price</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{1}</TableCell>
                      <TableCell>
                        <figure className="bnr_img">
                          <img
                            src={"/static/images/user_placeholder.png"}
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell>See the Northern Lights</TableCell>
                      <TableCell>www.northernlights.com</TableCell>
                      <TableCell>12/01/2024</TableCell>
                      <TableCell>$100</TableCell>
                      <TableCell>Accepted</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Card>
        ) : null}
        <DocumentViewer open={open} setOpen={setOpen} />
      </div>
    </MainContainer>
  );
};

export default ServiceProviderDetails;
