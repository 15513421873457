import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation, useNavigate } from "react-router-dom";

const AddSubscriptionPlan = () => {
  const location = useLocation();
  const { state } = location;
  const navigate = useNavigate();
  const [planType, setPlanType] = useState("");

  const handlePlanType = (event: SelectChangeEvent) => {
    setPlanType(event.target.value as string);
  };

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const planFeatures = [
    {
      id: 1,
      name: "No ads",
      enable: true,
    },
    {
      id: 2,
      name: " Create Unlimited challenges",
      enable: true,
    },
    {
      id: 3,
      name: "Create unlimited journeys",
      enable: true,
    },
    {
      id: 4,
      name: "Earn 1000 points",
      enable: true,
    },
  ];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          {state?.type === "edit" ? (
            <h1 className="mn_hdng">Edit Plan</h1>
          ) : (
            <h1 className="mn_hdng">Add Plan</h1>
          )}
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-subscription");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Name</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Name"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Plan Validity
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Validity"
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Type</Typography>
                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={planType}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handlePlanType}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="monthly">Monthly</MenuItem>
                      <MenuItem value="yearly">Yearly</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Plan Price</Typography>
                  <TextField
                    hiddenLabel
                    type={"email"}
                    name="email"
                    variant="outlined"
                    fullWidth
                    placeholder="Plan Price"
                  />
                </Grid>

                <Grid item lg={6} md={6} sm={6} xs={12}>
                  <Typography className="custom_label">Description</Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="firstName"
                    variant="outlined"
                    fullWidth
                    placeholder="Description"
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      component="h2"
                      sx={{
                        fontSize: "20px",
                        color: "#051140",
                        fontWeight: 700,
                      }}
                    >
                      Plan Features
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <TableContainer
                    className="table_container"
                    sx={{ margin: 0 }}
                  >
                    <Box className="heading"></Box>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="center">S.No</TableCell>
                          <TableCell>Feature</TableCell>
                          <TableCell> Status</TableCell>
                          {/* <TableCell>Actions</TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {planFeatures.map((row, i) => (
                          <TableRow key={i}>
                            <TableCell align="center">{i + 1}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <Switch {...label} defaultChecked size="small" />
                            </TableCell>
                            {/* <TableCell>
                              <Box className="table_actions">
                                <IconButton>
                                  <DeleteIcon />
                                </IconButton>
                              </Box>
                            </TableCell> */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button size="large" type="submit" className="btn btn_primary">
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSubscriptionPlan;
