import React from "react";
import MainContainer from "../../layout/MainContainer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";

const ManageContent = () => {
  const navigate = useNavigate();
  function createData(
    name: string,
    userName: string,
    phone: string,
    email: string,
    planName: string,
    planPrice: string,
    postedAds: string,
    earnings: string
  ) {
    return {
      name,
      userName,
      phone,
      email,
      planName,
      planPrice,
      postedAds,
      earnings,
    };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Abdul Hussian",
      "Abdul67",
      "+971056734321",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "10",
      "600 SAR"
    ),
  ];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Content/Posts</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar />
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Post Image</TableCell>
                  <TableCell>Post Description</TableCell>
                  <TableCell>User Details</TableCell>

                  <TableCell>Upload Date</TableCell>
                  <TableCell>Upload Time</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, i) => (
                  <TableRow key={row.name}>
                    <TableCell align="center">{i + 1}</TableCell>
                    <TableCell>
                      <figure className="user_img">
                        <img src="/static/images/post.png" alt="" />
                      </figure>
                    </TableCell>
                    <TableCell>Post Description</TableCell>
                    <TableCell>
                      <Typography variant="h6" fontSize={14}>
                        Randy Jackson
                      </Typography>
                      <Typography variant="h6" fontSize={14}>
                        randy@yopmail.com
                      </Typography>
                    </TableCell>

                    <TableCell>25/12/2023</TableCell>
                    <TableCell>10:23 AM</TableCell>

                    <TableCell>
                      <Switch {...label} defaultChecked size="small" />
                    </TableCell>
                    <TableCell>
                      <Box className="table_actions">
                        <IconButton
                          onClick={() => navigate("/manage-content/details")}
                        >
                          <VisibilityIcon />
                        </IconButton>
                        <IconButton>
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageContent;
