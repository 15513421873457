import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockClockIcon from "@mui/icons-material/LockClock";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { SIDEBAR_WIDTH } from "../constants";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import SavingsIcon from "@mui/icons-material/Savings";
import Person3Icon from "@mui/icons-material/Person3";
import NoteIcon from "@mui/icons-material/Note";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PaidIcon from "@mui/icons-material/Paid";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import { PropsWithoutRef } from "react";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const location = useLocation();
  const navigate = useNavigate();

  const drawer = (
    <>
      <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
        <figure>
          <img
            src="/static/images/logo.png"
            alt=""
            onClick={() => navigate("/dashboard")}
          />
        </figure>
        <IconButton
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "white",
            display: { lg: "none" },
          }}
          onClick={handleSidebarToggle}
        >
          <CloseIcon />
        </IconButton>
      </Toolbar>

      <List sx={{ flexGrow: 1 }} className="sidebr-lst">
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/dashboard"}
          onClick={() => navigate("/dashboard")}
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Dashboard" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manageusers"}
          onClick={() => navigate("/manageusers")}
        >
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Users" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-advertisers"}
          onClick={() => navigate("/manage-advertisers")}
        >
          <ListItemIcon>
            <Person3Icon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Advertisers" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-advertisements"}
          onClick={() => navigate("/manage-advertisements")}
        >
          <ListItemIcon>
            <FeaturedVideoIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Advertisements"
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-content"}
          onClick={() => navigate("/manage-content")}
        >
          <ListItemIcon>
            <ContentPasteIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Content/Posts" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-categories"}
          onClick={() => navigate("/manage-categories")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Challenge Categories "
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-subscription"}
          onClick={() => navigate("/manage-subscription")}
        >
          <ListItemIcon>
            <SubscriptionsIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Subscription Plans"
          />
        </ListItemButton>

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-amenities"}
          onClick={() => navigate("/manage-amenities")}
        >
          <ListItemIcon>
            <ManageAccountsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Amenities" />
        </ListItemButton> */}

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-listed-properties"}
          onClick={() => navigate("/manage-listed-properties")}
        >
          <ListItemIcon>
            <ListAltIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Listed Properties"
          />
        </ListItemButton> */}

        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-bookings"}
          onClick={() => navigate("/manage-bookings")}
        >
          <ListItemIcon>
            <NoteIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Manage Property Bookings"
          />
        </ListItemButton> */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-rewards"}
          onClick={() => navigate("/manage-rewards")}
        >
          <ListItemIcon>
            <EmojiEventsIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Rewards " />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/customer-support"}
          onClick={() => navigate("/customer-support")}
        >
          <ListItemIcon>
            <HelpCenterIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Customer Support" />
        </ListItemButton>
        {/* <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-reviews"}
          onClick={() => navigate("/manage-reviews")}
        >
          <ListItemIcon>
            <ThumbUpIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Reviews" />
        </ListItemButton> */}
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-challenges"}
          onClick={() => navigate("/manage-challenges")}
        >
          <ListItemIcon>
            <ThumbUpIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Challenges" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-words"}
          onClick={() => navigate("/manage-words")}
        >
          <ListItemIcon>
            <LockClockIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Words" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-revenue"}
          onClick={() => navigate("/manage-revenue")}
        >
          <ListItemIcon>
            <PaidIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Management Payment" />
        </ListItemButton>

        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-notifications"}
          onClick={() => navigate("/manage-notifications")}
        >
          <ListItemIcon>
            <NotificationsActiveIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Notifications" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/analytics"}
          onClick={() => navigate("/analytics")}
        >
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText
            className="lstitm-txt"
            primary="Reports and Analytics"
          />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/manage-subAdmin"}
          onClick={() => navigate("/manage-subAdmin")}
        >
          <ListItemIcon>
            <PersonAddIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage Sub-Admin" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/cms"}
          onClick={() => navigate("/cms")}
        >
          <ListItemIcon>
            <SummarizeTwoToneIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary="Manage CMS" />
        </ListItemButton>
        <ListItemButton
          className="lst-itm"
          selected={location.pathname === "/faq"}
          onClick={() => navigate("/faq")}
        >
          <ListItemIcon>
            <QuizIcon />
          </ListItemIcon>
          <ListItemText className="lstitm-txt" primary=" Manage FAQs" />
        </ListItemButton>
      </List>
    </>
  );

  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        {drawer}
      </Drawer>
    </Box>
  );
}
