import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

const ManageRewards = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");

  function createData(Name: string) {
    return { Name };
  }
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [createData("Adventurous")];
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Rewards</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
              // searchTerm={searchTerm}
              // setDebouncedSearchTerm={setDebouncedSearchTerm}
              // value={searchTerm}
              // onCross={() => setSearchTerm("")}
              // onChange={(val: any) => {
              //   if (isValidInput(val.target.value)) {
              //     setSearchTerm(val.target.value);
              //   }
              // }}
              />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-rewards/add")}
                >
                  Add Badge
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Badge Image</TableCell>
                    <TableCell> Name</TableCell>
                    <TableCell> Points Earned</TableCell>
                    <TableCell> No. of Journeys</TableCell>
                    <TableCell> No. of Posts</TableCell>
                    <TableCell> No. of Challenges</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>
                        <figure className="user_img">
                          <img src="/static/images/badge_image.png" alt="" />
                        </figure>
                      </TableCell>
                      <TableCell>{row.Name}</TableCell>
                      <TableCell>100</TableCell>
                      <TableCell> 10</TableCell>
                      <TableCell> 2</TableCell>
                      <TableCell> 8</TableCell>

                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() => navigate("/manage-rewards/add")}
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageRewards;
