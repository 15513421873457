import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import MainContainer from "../../layout/MainContainer";
import LineChart from "../../components/LineChart";
import { Box, Button, FormControl, Grid } from "@mui/material";
import NativeSelect from "@mui/material/NativeSelect";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const Analytics = () => {
  const graphRevenueData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Earnings",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphUsersData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Users",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphAdvertisersData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Advertisers",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  const graphTransactionsData = {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Transactions",
        data: [
          "10",
          "20",
          "25",
          "15",
          "50",
          "10",
          "20",
          "25",
          "15",
          "50",
          "20",
          "25",
        ],
        borderColor: "#f3bf59",
        backgroundColor: "#f3bf59",
      },
    ],
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <div className="flex">
            <h1 className="mn_hdng">Reports and Analytics</h1>
            <Box className="cards_header_right">
              <Button className="btn btn_primary">
                <FileDownloadIcon /> Export CSV
              </Button>
            </Box>
          </div>
        </div>
        <Grid container spacing={2} className="dashGraph">
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Users
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphUsersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Advertisers
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphAdvertisersData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Earnings
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphRevenueData} />
            </div>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <div className="cards dashGraph_item">
              <h2 className="mn_hdng">
                Transactions
                <FormControl>
                  <NativeSelect
                    defaultValue={0}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                  >
                    <option value={0}>Select</option>
                    <option value={10}>Day</option>
                    <option value={20}>Week</option>
                    <option value={30}>Monthly</option>
                    <option value={40}>Yearly</option>
                  </NativeSelect>
                </FormControl>
              </h2>
              <LineChart data={graphTransactionsData} />
            </div>
          </Grid>
        </Grid>
      </div>
    </MainContainer>
  );
};

export default Analytics;
