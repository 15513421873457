import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import Analytics from "./analytics";
import Faq from "./faq";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import ManageServiceProvider from "./manageAdvertiser";
import AddServiceProvider from "./manageAdvertiser/add";
import SubCategories from "./manageCategories/details";
import ChangePassword from "./auth/changePassword";
import ServiceProviderDetails from "./manageAdvertiser/details";
import ManageAmenities from "./manageAmenities";
import ManageListedProperties from "./manageListedProperties";
import PropertyDetails from "./manageListedProperties/details";
import EditProperties from "./manageListedProperties/edit";
import ManageBookings from "./manageBookings";
import BookingDetails from "./manageBookings/details";
import AddAmenities from "./manageAmenities/add";
import ManageReviews from "./manageReviews";
import ReviewDetails from "./manageReviews/details";
import ManageRevenue from "./revenueManagement";
import ManageWords from "./manageWords";
import ManageChallenges from "./ManageChallenges";
import AddChallenge from "./ManageChallenges/add";
import ChallengeDetails from "./ManageChallenges/details";
import ManageSubscription from "./manageSubscription";
import AddSubscriptionPlan from "./manageSubscription/add";
import ManageContent from "./manageContent";
import ContentDetails from "./manageContent/details";
import ManageRewards from "./manageRewards";
import AddRewards from "./manageRewards/add";
import ManageAdvertisements from "./manageAdvertisements";
import AdvertisementDetails from "./manageAdvertisements/details";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  DashBoardPage,
  ManageUsers,
  ForgotPasswordPage,
  VerifyOtp,
  Analytics,
  Faq,
  ResetPassword,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  ManageServiceProvider,
  AddServiceProvider,
  SubCategories,
  ChangePassword,
  ServiceProviderDetails,
  ManageAmenities,
  ManageListedProperties,
  PropertyDetails,
  EditProperties,
  ManageBookings,
  BookingDetails,
  AddAmenities,
  ManageReviews,
  ReviewDetails,
  ManageRevenue,
  ManageWords,
  ManageChallenges,
  AddChallenge,
  ChallengeDetails,
  ManageSubscription,
  AddSubscriptionPlan,
  ManageContent,
  ContentDetails,
  ManageRewards,
  AddRewards,
  ManageAdvertisements,
  AdvertisementDetails,
};
