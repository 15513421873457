import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";

const SubCategories = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const handleClose = () => {
    setOpen(false);
    // setSelectedId("");
    // formik.resetForm();
  };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Sub-Categories</h1>
        </div>
        <Card className="cards">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 0 }}>Travel</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{ marginRight: "10px" }}
                className="btn btn_primary"
                onClick={() => setOpen(true)}
              >
                Add Sub-category
              </Button>
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-categories")}
              >
                Back
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  {/* <TableCell align="center">Image</TableCell> */}
                  <TableCell>Sub-Category Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                <TableRow>
                  <TableCell align="center">{1}</TableCell>
                  {/* <TableCell>
                    {" "}
                    <figure className="user_img">
                      <img src="/static/images/user_placeholder.png" alt="" />
                    </figure>
                  </TableCell> */}
                  <TableCell>Adventure</TableCell>

                  <TableCell>
                    {" "}
                    <Switch {...label} defaultChecked size="small" />
                  </TableCell>
                  <TableCell>
                    <Box className="table_actions">
                      <IconButton onClick={() => setOpen(true)}>
                        <ModeEditIcon />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon />
                      </IconButton>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Sub-Category</Typography>
          <form>
            <FormControl fullWidth>
              <TextField
                hiddenLabel
                className="text_field"
                autoFocus
                id="category"
                name="category"
                type="text"
                placeholder="Add Sub-Category"
                fullWidth
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary">Add</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default SubCategories;
