import { useNavigate } from "react-router-dom";
import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import MainContainer from "../../layout/MainContainer";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ContentDetails = () => {
  const navigate = useNavigate();

  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">View Content Details</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-content");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <CardContent sx={{ p: 1 }}>
            <Grid container spacing={2} className="view_box">
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography
                  className="detail_title mn_hdng"
                  component="h2"
                  mb={3}
                >
                  User Details
                </Typography>
              </Grid>
              {/* <Grid item lg={2} md={2} sm={6} xs={12}>
                <figure className="profile_img">
                  <img src="/static/images/user_placeholder.png" alt="" />
                </figure>
              </Grid> */}
              <Grid item xs={12} className="view_box_list">
                <Grid container spacing={3}>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Name</Typography>
                      <Typography component="p">Randy Jackson</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Phone no</Typography>
                      <Typography component="p">+971056734321</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={3} md={3} sm={6} xs={12}>
                    <Box>
                      <Typography component="h5">Email</Typography>
                      <Typography component="p">randy@gmail.com</Typography>
                    </Box>
                  </Grid>

                  <Grid item mt={3} xs={12} className="view_box_list">
                    <Typography
                      className="detail_title mn_hdng"
                      component="h2"
                      mb={3}
                    >
                      Content Details
                    </Typography>
                    <Grid container spacing={3}>
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <Box>
                          <Typography component="h5">Image/Video</Typography>
                          <Box className="docs_div">
                            <figure>
                              {/* <VisibilityIcon
                                onClick={() => setOpenFrontImage(true)}
                              /> */}

                              <img src="/static/images/post.png" alt="" />
                            </figure>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item lg={6} md={6} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Description</Typography>
                          <Typography component="p">
                            {" "}
                            Lorem ipsum dolor sit amet consectetur, adipisicing
                            elit dolor sit amet consectetur. Lorem ipsum dolor
                            sit amet consectetur, adipisicing elit dolor sit
                            amet consectetur.
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Upload Date</Typography>
                          <Typography component="p">2/12/2023</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={3} md={3} sm={6} xs={12}>
                        <Box>
                          <Typography component="h5">Upload Time</Typography>
                          <Typography component="p">10:28 AM</Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Card sx={{ mt: 4 }} className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="Reported Comments" {...a11yProps(0)} />
                <Tab label="New Comments" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Comment</TableCell>
                      <TableCell>User Details</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontSize={14}>
                          Abdul
                        </Typography>
                        <Typography variant="h6" fontSize={14}>
                          abdul@yopmail.com
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell align="center">Comment</TableCell>
                      <TableCell>User Details</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{1}</TableCell>
                      <TableCell align="center">
                        {" "}
                        Lorem ipsum dolor sit amet consectetur, adipisicing elit
                        dolor sit amet
                      </TableCell>
                      <TableCell>
                        <Typography variant="h6" fontSize={14}>
                          Abdul
                        </Typography>
                        <Typography variant="h6" fontSize={14}>
                          abdul@yopmail.com
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ContentDetails;
