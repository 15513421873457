import React, { useState } from "react";
import MainContainer from "../../layout/MainContainer";

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Switch,
  Button,
  Tabs,
  Tab,
  Typography,
  DialogContent,
  TextField,
  Dialog,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";

const ManageSubscription = () => {
  const [value, setValue] = useState<number>(0);
  const [open, setOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    // formik.setFieldError("message", "");
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const navigate = useNavigate();
  function createData(
    name: string,
    planType: string,
    planPrice: string,
    email: string,
    planName: string,
    postedAds: string,
    earnings: string,
    bankAccounts: string
  ) {
    return {
      name,
      planType,
      planPrice,
      email,
      planName,
      postedAds,
      earnings,
      bankAccounts,
    };
  }
  // const label = { inputProps: { "aria-label": "Switch demo" } };
  const rows = [
    createData(
      "Plan 1",
      "Monthly",
      "$10",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "600 SAR",
      "2"
    ),
    createData(
      "Plan 2",
      "Monthly",
      "$79",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "600 SAR",
      "4"
    ),
    createData(
      "Plan 3",
      "Monthly",
      "$120",
      "abdulhussian67@gmail.com",
      "Starter",
      "1 Month / 600 SAR",
      "600 SAR",
      "6"
    ),
  ];

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const planFeatures = [
    {
      id: 1,
      name: "Enhanced Budget Tools",
      enable: true,
    },
    {
      id: 2,
      name: "Link Bank Accounts",
      enable: true,
    },
    {
      id: 3,
      name: "Advanced Security",
      enable: true,
    },
    {
      id: 4,
      name: "Track Spending Habbits",
      enable: true,
    },
    {
      id: 5,
      name: "Graphs and Charts",
      enable: true,
    },
    {
      id: 6,
      name: "Savings jar to visually watch the progress",
      enable: true,
    },
    {
      id: 7,
      name: "Recommendations and Analytics",
      enable: true,
    },
    {
      id: 8,
      name: "Educational Tips",
      enable: true,
    },
    {
      id: 9,
      name: "Savings Goals",
      enable: true,
    },
    {
      id: 10,
      name: "Round-Up Savings to Achieve Goals",
      enable: true,
    },
    // {
    //   id: 11,
    //   name: "With No Ads",
    //   enable: true,
    // },
    // {
    //   id: 12,
    //   name: "Notifications",
    //   enable: true,
    // },
    // {
    //   id: 13,
    //   name: "Reward Points",
    //   enable: true,
    // },
    // {
    //   id: 14,
    //   name: "Bill Reminders",
    //   enable: true,
    // },
    // {
    //   id: 15,
    //   name: "Multiplier to Accelerate Goals",
    //   enable: true,
    // },
    // {
    //   id: 16,
    //   name: "Manual ACH transfers between the external accounts",
    //   enable: true,
    // },
    // {
    //   id: 17,
    //   name: "Auto ACH transfers between the external accounts",
    //   enable: true,
    // },
  ];

  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Subscription Plans</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            {/* <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="basic tabs example"
              className="custom_tabs_links"
            >
              <Tab label="Plans" {...a11yProps(0)} />
              <Tab label="Features" {...a11yProps(1)} />
            </Tabs> */}
            {/* <CustomTabPanel value={value} index={0}> */}
            <Box className="cards_header">
              <SearchBar />
              <Box className="cards_header_right">
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-subscription/add")}
                >
                  Add Plan
                </Button>
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell>Plan Name</TableCell>
                    <TableCell>Plan Validity (months)</TableCell>
                    <TableCell>Plan Price</TableCell>
                    {/* <TableCell>Number of Bank Accounts </TableCell> */}

                    <TableCell> Status</TableCell>
                    <TableCell>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, i) => (
                    <TableRow key={row.name}>
                      <TableCell align="center">{i + 1}</TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>1 month</TableCell>
                      <TableCell>{row.planPrice}</TableCell>
                      {/* <TableCell align="center">{row.bankAccounts} </TableCell> */}

                      <TableCell>
                        <Switch {...label} defaultChecked size="small" />
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <IconButton
                            onClick={() =>
                              navigate("/manage-subscription/edit", {
                                state: { type: "edit" },
                              })
                            }
                          >
                            <ModeEditIcon />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {/* </CustomTabPanel> */}
            {/* <CustomTabPanel value={value} index={1}>
              <Box className="cards_header">
                <SearchBar />
                <Box className="cards_header_right">
                  <Button
                    className="btn btn_primary"
                    onClick={() => setOpen(true)}
                  >
                    Add Feature
                  </Button>
                </Box>
              </Box>
              <TableContainer className="table_container">
                <Box className="heading"></Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">S.No</TableCell>
                      <TableCell>Feature</TableCell>
                      <TableCell> Status</TableCell>
                      <TableCell>Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {planFeatures.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>
                          <Switch {...label} defaultChecked size="small" />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton onClick={() => setOpen(true)}>
                              <ModeEditIcon />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CustomTabPanel> */}
          </Box>
        </Card>
      </div>
      <Dialog open={open} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">Add Feature</Typography>
          <form>
            <TextField
              hiddenLabel
              className="text_field"
              autoFocus
              id="message"
              type="text"
              placeholder="Feature"
              fullWidth
              // value={formik.values.category}
              // onBlur={formik.handleBlur}
              // onChange={(val) => {
              //   if (val.target.value === " " || val.target.value === ".") {
              //   } else if (isString(val.target.value)) {
              //     formik.handleChange(val);
              //   }
              // }}
              // helperText={formik.touched.category && formik.errors.category}
            />

            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">
                Send
              </Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
    </MainContainer>
  );
};

export default ManageSubscription;
